


































































import {Component, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {MailingType, PurchaseRecord} from '~/components/data-class/data-class'
import {randomColor} from '~/utils/random-color'
import copy from 'copy-to-clipboard'
// import VueQrReader from '~/components/VueQrReader.vue'
import {sfLookup, updateLocations} from '~/components/sf-express/sf-location-storage'
import MailedOrderSelect from './mailed-order-select.vue'
import {ShopPermission} from '~/utils/permissions'
import WaybillScannerDialog from '~/components/waybill-scanner-dialog.vue'

@Component({
    components: {
        WaybillScannerDialog,
        MailedOrderSelect
    },
    metaInfo() {
        return {
            title: 'Mobile Coupon Purchase Record'
        }
    }
})

export default class PurchaseRecords extends Vue {
    static permission = ShopPermission.ShopPurchaseRecords

    dialogAddWaybillNumber = false
    MailingType = MailingType

    memberId = ''
    shopItemId: number | null = null
    mailingType: MailingType | null = null
    notSentOnly: boolean | null = null

    records: PurchaseRecord[] = []
    recordScanning: PurchaseRecord

    get isMobile() {
        const userAgent = navigator.userAgent
        const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1 // android终端
        const isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
        // return isAndroid || isiOS
        return true
    }

    async getRecords() {
        const res = await createRequest('/shop/purchase-records/admin', 'get', {not_sent_only: 'true', no_banned: 'true', limit: 0}).send()
        this.records = res.data.records
    }

    async created() {
        await this.getRecords()
        await updateLocations()
    }

    randomColor(str: string) {
        return randomColor(str)
    }

    sendSF(phoneNum, userName, shippingCode) {
        // find shipping data
        const sf = sfLookup[shippingCode]
        const shippingLocation = sf ? sfLookup[shippingCode].address : ''

        let str = ''

        if (userName != undefined) str += userName + '，'
        if (phoneNum != undefined) str += phoneNum + '，'

        str += shippingLocation ? shippingLocation + '（' + shippingCode + '）' : shippingCode

        copy(str, {
            debug: false,
            message: 'Press #{key} to copy',
        })

        this.$message('Coped!!')

        const userAgent = navigator.userAgent
        const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1 // android终端
        const isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端

        if (isAndroid || isiOS) {
            window.location.href = 'suyuntonghmto://sf.com'
        }
    }

    startScan(record: PurchaseRecord) {
        this.recordScanning = record
        this.dialogAddWaybillNumber = true
    }

    codeScanned(awbNo: string) {
        createRequest('/shop/purchase-record/send', 'post', {}, {
            _id: this.recordScanning._id,
            waybill_number: awbNo
        }).send()
            .then(() => {
                this.recordScanning.waybill_number = awbNo
            })
            .catch((e) => {
                console.log(e)
                this.$message({
                    message: 'Error ' + JSON.stringify(e),
                    type: 'error'
                })
            })
    }

    orderSelected(record: PurchaseRecord) {
        createRequest('/shop/purchase-record/send', 'post', {}, {
            _id: record._id,
            order_id: record.order_id
        }).send()
            .then((res) => {
                const nRecord: PurchaseRecord = res.data.record
                record.waybill_number = nRecord.waybill_number
                this.$message({
                    message: 'Success',
                    type: 'success'
                })
            })
            .catch((e) => {
                console.log(e)
                this.$message({
                    message: 'Error ' + JSON.stringify(e),
                    type: 'error'
                })
            })
    }

    newBatchSF() {
        const itemList = this.records.filter(item => {
            return item.mailing_type === MailingType.SF
        })

        const rows: string[] = []
        for (const item of itemList) {
            console.log(item['shipping_code'])
            const code = item.shipping_code || ''
            let address = sfLookup[code] ? sfLookup[code].address : code

            if (!address.startsWith('香港'))
                address = '香港' + address

            const orderId = item._id.member_id
            const shopItem = `${item.title} x${item.count}`
            rows.push([orderId, '中国香港', 0, '公司名稱 : AfterSchool', 'AfterSchool', '91036749', '香港長沙灣紗廠工業大廈5期11樓C室', '', '', '中国香港', 0, '', orderId, '', item.phone, address, '', '', '', '', '', '', '', shopItem, shopItem, 50, '港幣', 1, '件', '', shopItem, '到付現結', '順豐特快'].join('\t'))
        }

        copy(rows.join('\n'), {
            debug: false,
            message: 'Press #{key} to copy',
        })

        this.$message('Coped!!')
    }
}


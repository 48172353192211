


















import {Component, Emit, Prop, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {MailingStatus, Order} from '~/components/data-class/data-class'

@Component({
    components: {}
})
export default class MailedOrderSelect extends Vue {
    @Prop({
        default: () => {
            return ''
        }
    })

    value: string

    @Prop()
    size: string
    @Prop()
    memberId: string

    orders: Order[] = []
    async created() {
        const q = {
            member_id: this.memberId,
            mailing_status: MailingStatus.MAILED.toString()
        }
        const res = await createRequest('/courses/orders', 'get', q).send()
        this.orders = res.data.orders
    }

    @Emit()
    input(value: string) {
        //
    }

    get localValue(): string {
        return this.value
    }

    set localValue(value: string) {
        this.input(value)
    }
}
